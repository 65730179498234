<template>
  <div class="loginBox">
    <div class="login">
      <p class="login-left" />
      <div class="login-box">
        <ul class="tabs">
          <li
            :key="item.key"
            v-for="(item, index) in tabsList"
            @click="setActiveName(item.key)"
            :style="index === 0 ? 'margin-right:40px' : ''"
            :class="{ highlight: activeName === item.key, hastwo:tabsList.length !== 1, [theme]: theme }"
          >{{ item.val }}</li>
        </ul>

        <el-form class="form" ref="form" :rules="rules" :model="formData">
          <el-form-item prop="account" ref="account">
            <el-input
              :placeholder="'请输入'+placeholderTxt"
              v-model="formData.account"
              @keyup.enter.native="onSubmit"
              key="account"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" v-if="activeName === 'password'" ref="password">
            <el-input :type="passw" placeholder="请输入登录密码" v-model="formData.password" key="password" clearable></el-input>
          </el-form-item>
          <el-form-item v-else prop="authCode" ref="authCode">
            <el-input placeholder="请输入验证码" v-model="formData.authCode" @keyup.enter.native="onSubmit">
              <el-button
                slot="append"
                @click="postAccountGetAuthCode"
                :style="sendCodeBtnStyle"
                round
              >{{ 0 >= Countdown ? '发送验证码' : Countdown+'s' }}</el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="loginbtn" :class="{[theme]:theme}" @click="onSubmit">登录</el-button>
          <div v-show="activeName === 'password'" class="forgetpsd">
            <router-link class="fr" :to="{ path: '/forget' }" style="color:#5F6368">忘记密码？</router-link>
          </div>
        </div>
        <div class="read" :class="{[theme]:theme}">
            <el-checkbox v-model="hasreaded">我已阅读并同意</el-checkbox>
            <template v-if="theme!='renshefenguan'">
              <span class="agreement" @click="goPage(user_agreement_url)">《用户协议》</span>
              <span style="color:#666">及</span>
              <span class="agreement" @click="goPage(privacy_policy_url)">《法律声明及隐私政策》</span>
            </template>
            <template v-else>
              <span class="agreement" @click="goPage('registerProtocol')">《用户注册协议》</span>
              <span class="agreement" @click="goPage('user-privacy')">《个人信息保护制度》</span>
              <span class="agreement" @click="goPage('network-regulation')">《网络安全管理制度》</span>
            </template>
        </div>
        <div class="footerTip" v-if="canRegister">
          <span class="tip">还没账号？ </span>
          <a href="javascript:;" class="view" :class="{[theme]:theme}" @click="toRegister"> 立即注册 ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from "jquery";
import md5 from "js-md5";
import config from '@/config/url'
import { isPhoneLogin, isEmail, isIdentitycard } from '@/utils/validator'
export default {
  name: "login",
  data() {
    const accountValidator = (rule, value, callback) => {
      if(this.isAllowStudentNumberLogin){//如果允许校号登录则不加校验
        callback()
      }
      if ( isPhoneLogin.test(value) || isEmail.test(value) || isIdentitycard.test(value) ) {
        callback()
      } else {
        callback(new Error('请输入'+this.placeholderTxt))
      }
    }
    return {
      user_agreement_url:'https://wd-static-page.wdeduc.com/wdn-h5-agreement/service-agreement.html',
      privacy_policy_url:'https://wd-static-page.wdeduc.com/wdn-h5-agreement/privacy.html',
      tabsList: [
        { key: "password", val: "密码登录" },
        { key: "Verification", val: "验证码登录" }
      ],
      activeName: "password",
      passw: "password",
      CountdownInterval: null,
      Countdown: 0,
      smallImg: "",
      rules: {
        account: [
          {
            validator: accountValidator.bind(this),
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
      formData: {
        account: null,
        password: null,
        authCode: null
      },
      bigImg: "",
      loginMethodList:['1','2','3','4','5'],//1,3验证码，1手机号3邮箱，           2，4，5密码 ，2手机号，4邮箱， 5身份证
      logintxt:'手机号码/邮箱/身份证号码',
      canRegister:false,
      hasreaded:false,
      isAllowStudentNumberLogin:false,//是否允许校号登录（青岛恒星）
      loginBy:0, //0学员登录、1机构登录
      branchId: '', // 机构id
      yingjiGmBranchUcodeObj: {
            //应急山东省branchucode
            fat: '6cb2d6910c494d3f93a93d13c8a92700',
            uat: 'bfca586d2ca04de3b3f670fa33e931ac',
            prod: 'e17c634bc0eb40e4a86c896f51c5ddd9',
        },
      feimeiBranchUcodeObj:{
            //应急非煤培训branchucode
            fat: '3bd092d9e2f9439fad76f732d00c4177',
            uat: "53b55255ceb54028802fbabf3f33ba0c",
            prod: "3efd741de4f7465685df29f1ce14ff31",
        },
      yingjiSlfhBranchUcodeObj:{
        fat: "5e905624cc674f29a5f681905cd5c457",
        uat: "ec168785e2834f258d82565e37eca396",
        prod: "e2fb99d769894f8b955b72279c937b83",
      },

      yingjiYSXBranchUcodeObj:{
        fat: "be6eac7e4bbf49bda567a39e661ded30",
        uat: "12f7bf11acff4ec28980ca11fbcbbd3b",
        prod: "4676f7d830f149f58f5d55de913720ec",
      },
    };
  },
  created() {
    this.loginBy = this.$route.query.loginBy || localStorage.getItem('loginBy') ||  0

    const branchId = localStorage.getItem('branchId')

    const saasId = localStorage.getItem('saasId')
    const devOptions = localStorage.getItem('devOptions')

    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('loginBy',this.loginBy)
    localStorage.setItem('branchId', branchId)
    localStorage.setItem('devOptions', devOptions)
    this.branchId = branchId
    localStorage.setItem('saasId', saasId)
    let url= config.confUrl
    this.configuration(url);
  },
  computed:{
    theme(){
      return this.$store.state.theme
    },
    placeholderTxt(){
      if(this.isAllowStudentNumberLogin){
        return '校号/手机号/邮箱/身份证号码'
      }
      if(this.tabsList.length == 1 && this.activeName === 'Verification' && this.logintxt.includes('身份证')){
        let index = this.logintxt.lastIndexOf('/')
        return this.logintxt.slice(0,index)
      }else if(this.tabsList.length == 2 && this.activeName === 'password'){
        let strToArr = this.logintxt.split('/')
        if(!this.loginMethodList.includes('2') && this.logintxt.includes('手机号码')){
          let index = strToArr.indexOf('手机号码')
          strToArr.splice(index,1)
        }
        if(!this.loginMethodList.includes('4') && this.logintxt.includes('邮箱')){
          let index = strToArr.indexOf('邮箱')
          strToArr.splice(index,1)
        }
        return strToArr.join('/')
      }else if(this.tabsList.length == 2 && this.activeName === 'Verification'){
        let strToArr = this.logintxt.split('/')
        let ind = strToArr.indexOf('身份证号码')
        if(ind != -1){
          strToArr.splice(ind,1)
        }
        if(!this.loginMethodList.includes('1') && this.logintxt.includes('手机号码')){
          let index = strToArr.indexOf('手机号码')
          strToArr.splice(index,1)
        }
        if(!this.loginMethodList.includes('3') && this.logintxt.includes('邮箱')){
          let index = strToArr.indexOf('邮箱')
          strToArr.splice(index,1)
        }
        return strToArr.join('/')
      }else{
        return this.logintxt
      }
    },
    sendCodeBtnStyle(){
      let background = '#316fff'
      if(this.theme === 'yingji'){
        background = '#D63535'
      }else if(this.theme === 'wanghong'){
        background = 'linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%)'
      }else if(this.theme === 'shanghaijiaoda'){
        background = '#C51022'
      }
      return {
          background:0 >= this.Countdown ? background:'#BFC8D9',
          border:'none'
      }
    },
    is_login_current_branch() {
        let is_login_current_branch = this.$store.state.dynamic.frontendConfig.find((item) => item.key === 'is_login_current_branch')
        if (is_login_current_branch) {
        	if (is_login_current_branch.value === 'true') {
        		return true
        	}
        }
        return false
    },
    is_priority_verification_code_login(){
        let is_priority_verification_code_login = this.$store.state.dynamic.frontendConfig.find((item) => item.key === 'is_priority_verification_code_login')
        if (is_priority_verification_code_login) {
        	if (is_priority_verification_code_login.value === 'true') {
        		return true
        	}
        }
        return false
    },
    /* 行业标签
        meet_emergency应急
    */
    branchIndustry() {
        return this.$store.state.dynamic.pageSetData.branchIndustry
    },
    yingjiBranchUcode() {
        //应急山东省branchucode
        let envArr = ['fat', 'uat']
        let env = 'prod'
        envArr.forEach((d) => {
            if (window.location.hostname.includes(d)) {
                env = d
            }
        })
        // 应急登录定制化配置
        let devOptions = localStorage.getItem('devOptions')
        if(devOptions && devOptions.length){
            devOptions = JSON.parse(devOptions)[0]
            if(devOptions.sign === 'gongmao'){
                console.log('gongmao--------------------------')
                return this.yingjiGmBranchUcodeObj[env]
            }else if(devOptions.sign === 'weihua'){
                console.log('weihua---------------------------')
                return this.yingjiBranchUcodeObj[env]
            }else if(devOptions.sign === 'feimei'){
                console.log('feimei---------------------------')
                return this.feimeiBranchUcodeObj[env]
            }else if(devOptions.sign === 'slfh'){
                console.log('slfh---------------------------')
                return this.yingjiSlfhBranchUcodeObj[env]
            }else if(devOptions.sign === 'ysx'){
                console.log('slfh---------------------------')
                return this.yingjiYSXBranchUcodeObj[env]
            }
        }
        return ''
        // //应急山东省branchucode
        // let envArr = ['fat', 'uat']
        // let env = 'prod'
        // envArr.forEach((d) => {
        //     if (window.location.hostname.includes(d)) {
        //         env = d
        //     }
        // })
        // return this.yingjiGmBranchUcodeObj[env]
    },
  },
  mounted() {
    console.log(this.theme)
  },
  methods: {
      //   隐私协议跳转
    goPage(url){
        let URL = url.includes('http') ? url : `${window.location.origin}/${url}`
        window.open(URL)
    },
    handleLoginTxt(){
      let result = '手机号码/邮箱/身份证号码'
      if(!this.loginMethodList.includes('1') && !this.loginMethodList.includes('3')){
        //密码
        result = this.getMethodTxt()
        this.tabsList = [{ key: "password", val: "密码登录" }]
      }else if(!this.loginMethodList.includes('2') && !this.loginMethodList.includes('4') && !this.loginMethodList.includes('5')){
        //验证码
        result = this.getMethodTxt()
        this.tabsList = [{ key: "Verification", val: "验证码登录" }]
        this.activeName = 'Verification'
      }else{
        //验证码+密码
        result = this.getMethodTxt()
        if(this.is_priority_verification_code_login){
            this.tabsList = [
                { key: "Verification", val: "验证码登录" },
                { key: "password", val: "密码登录" },
            ]
            this.activeName = 'Verification'
        }else{
            this.tabsList = [
                { key: "password", val: "密码登录" },
                { key: "Verification", val: "验证码登录" }
            ]
        }
      }
      this.logintxt = result
    },
    getMethodTxt(){
      let hasphone,hasemail,hasidentifycard = ''
      if(this.loginMethodList.includes('1') || this.loginMethodList.includes('2')) hasphone = '手机号码'
      if(this.loginMethodList.includes('3') || this.loginMethodList.includes('4')) hasemail = '邮箱'
      if(this.loginMethodList.includes('5')) hasidentifycard = '身份证号码'
      let arr = [hasphone,hasemail,hasidentifycard].filter(item => {return !!item})
      return arr.join('/')
    },
    setActiveName(val) {
      if(val === this.activeName) return
      this.activeName = val;
      this.$refs.account.clearValidate();
      if (this.activeName === "password") {
        this.$refs.authCode.clearValidate();
      } else {
        this.$refs.password.clearValidate();
      }
    },
    configuration(url) {
      this.$api.configure.configurationNew(url).then(res => {
        if (res.data) {
            this.branchUcode = res.data.branchUcode
          let frontendConfig = res.data.frontendConfig.find(item => {
              return item.key === "homepage";
          });

          // 获取h5域名
            let h5_address = res.data.frontendConfig.find((item) => item.key === 'h5_address')
            if(h5_address && h5_address.value){
                localStorage.setItem('domain',JSON.stringify([{domain:h5_address.value,frontend_template_id:4}]))
            }else if(res.data.solutionInfo && res.data.solutionInfo.domain){
              localStorage.setItem('domain', JSON.stringify(res.data.solutionInfo.domain)?JSON.stringify(res.data.solutionInfo.domain):'');
            }
          localStorage.setItem("hasGetConfig", "1");
          localStorage.setItem("theme", res.data.theme || "");
          this.$store.commit('setTheme',res.data.theme || "")
          localStorage.setItem("frontendConfig",frontendConfig ? JSON.stringify(frontendConfig) : "");
          localStorage.setItem("configurationArr",JSON.stringify(res.data.frontendConfig));
          // 修改页签信息
          if (res.data.websiteSetting && res.data.websiteSetting.pageLogo) {
            var link = document.querySelector("link[rel*='icon']");
            link.href = res.data.websiteSetting.pageLogo;
            localStorage.setItem('pageLogo', res.data.websiteSetting.pageLogo)
          }
          if (res.data.websiteSetting && res.data.websiteSetting.pageTitle) {
            document.title = res.data.websiteSetting.pageTitle;
            localStorage.setItem('pageTitle', res.data.websiteSetting.pageTitle)
          }
          this.initConfig();
        }
      });
    },
    initConfig() {
      /*
        手机号+验证码 is_allow_telephone_verification_code_login 1
        手机号+密码   is_allow_telephone_password_login 2
        邮箱+验证码   is_allow_email_verification_code_login 3
        邮箱+密码     is_allow_email_password_login 4
        身份证+密码   is_allow_id_number_password_login 5
        注册方式:手机号+验证码  is_allow_telephone_register
        注册方式:邮箱+验证码    is_allow_email_register
      */
      let configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
      if (configurationArr.length>0) {
        configurationArr.forEach(item => {
          switch (item.key) {
            case 'privacy_policy_url':
              this.privacy_policy_url = item.value || this.privacy_policy_url;
              break;
            case 'user_agreement_url':
              this.user_agreement_url = item.value || this.user_agreement_url;
              break;
            case 'custom_login_background_img':
              this.bigImg = item.value;
              break;
            case 'custom_login_img':
              this.smallImg = item.value;
              break;
            case 'is_open_register':
              this.canRegister = item.value === 'true'? true:false
              break;
            case 'is_allow_student_number_password_login':
              if(item.value === 'true'){
                this.isAllowStudentNumberLogin = true
              }
            break;
            case 'is_allow_telephone_verification_code_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('1')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_telephone_password_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('2')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_email_verification_code_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('3')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_email_password_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('4')
                this.loginMethodList.splice(index,1)
              }
              break;
            case 'is_allow_id_number_password_login':
              if(item.value === 'false'){
                let index = this.loginMethodList.indexOf('5')
                this.loginMethodList.splice(index,1)
              }
              break;
            default:
              break;
          }
        })

        const imgsrc1 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png";
        const imgsrc2 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/login/rectangle.png";
        this.$nextTick(() => {
          // 防止背景图闪现两次
          $(".loginBox").css("background-image",`url(${this.bigImg ? this.bigImg : imgsrc1})`);
          $('.login-left').css('background-image', `url(${this.smallImg ? this.smallImg : imgsrc2})`)
        });
        this.handleLoginTxt()
      }
    },
    async onSubmit() {

      this.$refs.form.validate(valid => {
        if (valid) {
            if(!this.hasreaded){
                this.$message.warning('请勾选用户协议')
                return false;
            }
          this.toLogin();
        } else {
          return false;
        }
      });
    },
    async postAccountGetAuthCode() {
      const data = { account: this.formData.account,type:1 };
      this.formData.authCode = null;
      if (isPhoneLogin.test(this.formData.account) || isEmail.test(this.formData.account)) {
        if (this.Countdown > 0) return;
        this.Countdown = 60;
        this.setCountdown();
        this.$sensors.track('UM_Click_Verification_Code_Button')
        this.$api.usercenter.getAuthCodeCommon({ data })
      }
    },
    async toLogin() {
      localStorage.removeItem("organ")
      localStorage.removeItem("actor")
      localStorage.removeItem('branch')
        const data = {...this.formData};
        data.password && (data.password = md5(data.password).toLowerCase())
        this.loginBy == 1 && (data.adminFlag = 1) //管理员登录

        let method = ''
        if(this.activeName === 'password'){//密码登录
            method = 'postAccountLogin'
        }else if(this.is_login_current_branch){//验证码登录（没有账号直接注册到当前机构）
            method = 'login2Branch'
            data.branchUcode = this.branchUcode
        }else {//验证码登录（没有账号直接注册到当前租户根机构）
            method = 'postAccountLoginByAuthCode'
        }

      await this.$api.usercenter[method]({ data })
        .then(async res => {
          if (res.data) {
            localStorage.setItem("token", res.data);

            try {
              await this.getFindUserBranchList()
            } catch (error) {
              if(error) {
                  this.$message.error(error)
                  return
              }
            }

            await this.getAccountUserInfo()//账号
            await this.getUserInfo()//不同机构下
            console.log(this.$route.query.fullPath,'this.$route.query.fullPath')
            if (this.$route.query.name) {

                this.$router.push({
                    path: this.$route.query.name + window.location.search
                })
            } else if (this.$route.query.fullPath) {
                this.$router.push({
                    path: this.$route.query.fullPath
                })
            } else {
                let devOptions = localStorage.getItem('devOptions')
                if(!(devOptions=='null')){
                    let homepage = ''
                    if (devOptions && devOptions.length) {
                       let devOptionsign = JSON.parse(devOptions)[0]
                        homepage =  '/' + devOptionsign.sign
                    }
                    this.$router.push({
                        path: homepage
                    })
                }else {
                    const frontendConfig = localStorage.getItem('frontendConfig') ? JSON.parse(localStorage.getItem('frontendConfig')) : ''
                    let path = '/learning'
                    if (frontendConfig.key == 'homepage' && frontendConfig.value) {
                        path = '/'+frontendConfig.value
                    }
                    this.$router.push({
                        path: path
                    })
                }


            }
          } else {
            return res.data;
          }
        })
        .catch(() => {
          return false;
        });
    },
    async getAccountUserInfo () {
      await this.$api.usercenter.getAccountUserInfo({}).then(res => {
        this.$store.commit('saveUserInfo',res.data)
        localStorage.setItem('userInfo',JSON.stringify(res.data))
      })
    },
    async getUserInfo(){
      console.log('getUserInfogetUserInfogetUserInfo')

      await this.$api.usercenter.findUser().then(res => {
        if(res.code == 0){
          this.$store.commit('saveSubUserInfo',res.data)
          localStorage.setItem('subUserInfo',JSON.stringify(res.data))
        }
      })
    },
    getFindUserBranchList() {
      return this.$api.usercenter.getFindUserBranchList({}).then(res => {
        if (res.data && res.data.length) {
          const branchList = res.data
            .map(item => {
              return {
                name: item.name,
                id: item.branch,
                actor: item.actor,
                organ: item.organ,
                userId: item.userId,
                code: item.code,
                token: item.token,
                branchUcode: item.branchUcode
              };
            });

            let currentBranch = branchList[0]
            if(this.branchIndustry === 'meet_emergency'){//应急定制---登录到山东省
                let shandongBranch = branchList.find((item) => {
                    return item.branchUcode === this.yingjiBranchUcode
                })
                currentBranch = shandongBranch || null
            }else if (this.is_login_current_branch) {
                // 登录域名指定的子机构
                currentBranch = branchList.find((item) => {
                    let branch = md5(this.branchId + item.token).toUpperCase()
                    return item.id === branch
                })
            }
            if(!currentBranch){
                return Promise.reject('暂未加入到当前机构！')
            }

            localStorage.setItem("branchList", JSON.stringify(branchList));
            localStorage.setItem("code", currentBranch.code);
            localStorage.setItem("organ", currentBranch.organ);
            localStorage.setItem("actor", currentBranch.actor);
            localStorage.setItem("branch", currentBranch.id);
            localStorage.setItem("userId", currentBranch.userId);
            this.$sensors.login(currentBranch.code)

        }else{
            return Promise.reject(res.message)
        }
      });
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    toRegister(){
      this.$router.push('/register')
    }
  }
};
/* eslint-disable no-new */
</script>

<style lang="stylus" scoped>
@import './../css/login';

.tabs {
  background: #fff;
  font-size: 24px;
  color: #999;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-left: 0;
  text-align: left;

  li {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    margin-bottom: 0;

    &.highlight.yingji {
      border-bottom:none;
      color:#D63535;
    }
    &.highlight.wanghong {
      border-bottom:none;
      color:#AD6ADE;
    }
    &.highlight.shanghaijiaoda {
      border-bottom:none;
      color:#C51022;
    }
    &.highlight {
      border-bottom:none;
      color:#316FFF;
    }

    &.hastwo:first-child::after {
      position: absolute;
      right: -20px;
      top: 14px;
      content: '';
      display: inline-block;
      width: 1px;
      height: 15px;
      background: #E6E6E6;
    }
  }
}

.login {
  .login-btn {
    width: 190px;
    height: 36px;
    padding: 0;
    border-radius: 22px;
    background: #316fff;
    border: none;
  }

  /deep/ .el-button {
    color: #fff;
  }

  /deep/.el-button--primary >span {
    font-size: 14px;
  }

  /deep/ .el-form-item.is-error .el-input__inner, /deep/ .el-form-item .el-input__inner:focus {
    border-color: #D8D8D8;
    caret-color: #316fff;
  }

  /deep/ .el-button--info.is-disabled, /deep/ .el-button--info.is-disabled:active, /deep/ .el-button--info.is-disabled:focus, /deep/ .el-button--info.is-disabled:hover {
    background-color: #DEDDDE;
    border: #DEDDDE;
  }

  .forget {
    display: inline-block;
    margin-left: 20px;

    .fr {
      color: #5F6368;
    }
  }
}

.el-form-item {
  margin-top: 30px;
  margin-right: 0;
  // .sendCodeBtn.yingji:hover{
  //   background:#D63535 !important;
  // }
  // .sendCodeBtn.wanghong:hover{
  //   background:linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
  // }
}
.read{
    margin-top 10px
    .agreement{
        color #0F3EAA
        cursor pointer
    }
}
.btn {
  display: flex;
  align-items: center;
  margin-top: 60px;

  .loginbtn {
    width: 190px;
    height: 36px;
    margin-right: 20px;
    padding: 0;
    border-radius: 22px;
    background: #316fff;
    border:none;
  }
  .loginbtn.yingji{
    background: #D63535;
  }
  .loginbtn.yingji:hover{
    background: #D63535 !important;
  }
  .loginbtn.shanghaijiaoda{
    background: #C51022;
  }
  .loginbtn.shanghaijiaoda:hover{
    background: #C51022 !important;
  }
  .loginbtn.wanghong{
    background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%);
  }
  .loginbtn.wanghong:hover{
    background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
  }

  /deep/ .el-button--primary span {
    font-size: 14px;
  }
}

.footerTip {
  display: flex;
  margin-top: 20px;

  .tip {
    font-size: 12px;
    color: #5F6368;
  }

  .view {
    font-size: 12px;
    color: #1972ff;
  }
  .view.yingji{
    color: #D63535;
  }
  .view.wanghong{
    color: #AD6ADE;
  }
  .view.shanghaijiaoda{
    color: #C51022;
  }
}

.mt20.ct {
  font-size: 16px;
  color: #999999;
}



</style>
