<template>
  <div class="loginBox forget">
    <div class="login">
      <p class="login-left" />
      <div class="login-box">
        <div class="title" >
          重置密码
        </div>
        <div class="progress">
          <span class="line1" :class="{active:step >= 2}"></span>
          <span class="line2" :class="{active:step == 3}"></span>
          <div class="outer-1" :class="{active:step >= 1}">
            <div class="inner">1</div>
            <span class="txt">身份验证</span>
          </div>
          <div class="outer-2" :class="{active:step >= 2}">
            <div class="inner">2</div>
            <span class="txt">设置新密码</span>
          </div>
          <div class="outer-3" :class="{active:step == 3}">
            <div class="inner">3</div>
            <span class="txt">重置成功</span>
          </div>
        </div>
        <div class="form">
          <template v-if="step == 1">
            <el-form ref="form" :rules="rules" :model="formData">
              <el-form-item prop="account" ref="account"> 
                <el-input
                  placeholder="请输入手机号/邮箱账号"
                  v-model="formData.account"
                  @keyup.enter.native="onSubmit"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="authCode" ref="authCode">
                <el-input
                  placeholder="请输入验证码"
                  v-model="formData.authCode"
                  @keyup.enter.native="onSubmit"
                >
                  <el-button slot="append" @click="postAccountGetAuthCode" round :style="sendCodeBtnStyle"
                    >{{ 0 >= Countdown ? '发送验证码' : Countdown }}
                  </el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </template>
          <template v-else-if="step == 2">
            <el-form ref="form1" :rules="rules1" :model="formData1">
              <el-form-item prop="password" key="password"> 
                <el-input
                  @blur="comparePassword"
                  placeholder="请设置8-20位，数字、大小写字母、字符组合密码"
                  :type="passw"
                  v-model="formData1.password"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="rePassword" key="rePassword">
                <el-input
                  @blur="comparePassword"
                  placeholder="请再次输入登录密码"
                  :type="passw"
                  v-model="formData1.rePassword"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-form>
          </template>
          <template v-else>
            <div class="success">
              <div class="success-txt">恭喜您，密码重置成功！</div>
              <div class="tip">请妥善保管您的账户信息</div>
            </div>
          </template>
        </div>
        <div class="btn">
          <el-button
            class="loginbtn"
            :class="{[theme]:theme}"
            @click="nextStep(step)"
            >{{step == 1? '下一步':step == 2?'确认重置':step == 3? '去登录':''}}
          </el-button>
          <div class="forgetpsd" v-if="step != 3">
            <a class="fr"  @click="$router.go(-1)" style="cursor:pointer">
              返回登录 >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import config from '@/config/url'
import { isPhone, isEmail, passwordValidator } from '@/utils/validator'

export default {
  name: "forget",
  data() {
    const accountValidator = (rule, value, callback) => {
      if (isPhone.test(value) || isEmail.test(value) ){
        callback();
      } else {
        callback(new Error("请输入正确的手机号/邮箱账号"));
      }
    }
    return {
      step:1,
      Countdown:0,
      passw: "password",
      rules: {
        account: [
          {
            required: true,
            message: "请输入手机号/邮箱账号",
            trigger: "blur"
          },
          {
            validator: accountValidator,
            trigger: "blur"
          }
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入六位验证码", trigger: "blur" }
        ]
      },
      rules1: {
        password: [
          { required: true, message: "登录密码不能为空", trigger: "blur" },
          { validator: passwordValidator, trigger: 'blur' }
        ],
        rePassword: [{ required: true, message: "登录密码不能为空", trigger: "blur" }]
      },
      formData: {
        account: null,
        authCode: null
      },
      formData1: {
        password:null,
        rePassword:null,
      }
    };
  },
  created () {
    if (!localStorage.getItem('hasGetConfig')) {
      let url= config.confUrl
      this.configuration(url)
    } else {
      this.initConfig();
    }
  },
  computed:{
    theme(){
      return this.$store.state.theme
    },
    sendCodeBtnStyle(){
      let background = '#316fff'
      if(this.theme === 'yingji'){
        background = '#D63535'
      }else if(this.theme === 'wanghong'){
        background = 'linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%)'
      }
      
      return {
          background:0 >= this.Countdown ? background:'#BFC8D9',
          border:'none'
      }
    }
  },
  mounted() {
    
  },
  methods: {
    configuration(url){
      this.$api.configure.configurationNew(url).then(res => {
        if(res.data){
          let frontendConfig = res.data.frontendConfig.find(item => {
              return item.key === "homepage";
          });
          localStorage.setItem("hasGetConfig", "1");
          localStorage.setItem("theme", res.data.theme || "");
          this.$store.commit('setTheme',res.data.theme || "")
          localStorage.setItem("frontendConfig",frontendConfig ? JSON.stringify(frontendConfig) : "");
          localStorage.setItem("configurationArr",JSON.stringify(res.data.frontendConfig));
          this.configurationArr = res.data.frontendConfig;
          this.initConfig();
        }
      })
    },
    initConfig() {
      let configurationArr = JSON.parse( localStorage.getItem('configurationArr') )
      if (configurationArr.length>0) {
        configurationArr.forEach(item => {
          switch (item.key) {
            case 'custom_login_background_img':
              this.bigImg = item.value;
              break;
            case 'custom_login_img':
              this.smallImg = item.value;
              break;
            default:
              break;
          }
        })
        const imgsrc1 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/beijin.png";
        const imgsrc2 = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/login/rectangle.png";
        this.$nextTick(() => {
          // 防止背景图闪现两次
          $(".loginBox").css("background-image",`url(${this.bigImg ? this.bigImg : imgsrc1})`);
          $('.login-left').css('background-image', `url(${this.smallImg ? this.smallImg : imgsrc2})`)
        });
      }
    },
    async nextStep(step) {
      if(step === 1){
        this.$refs.form.validate(valid => {
          if (valid) {
            this.checkAuthCode()
          } else {
            return false;
          }
        });
      }else if(step === 2){
        if(this.comparePassword()){
          this.resetPassword()
        }
      }else{
        this.$router.replace('/login')
      }
    },
    checkAuthCode(){
      let result = false
      let data = {...this.formData}
      this.$api.usercenter
        .checkAuthCode(data)
        .then(res => {
          result = res.data
          if(result){
            this.step = 2
          }
        });
    },
    resetPassword(){
      let result = false
      let data = {...this.formData,password:this.formData1.password}
      this.$api.usercenter.resetPassword({ data })
        .then(res => {
          result = res.data
          if(result){
            this.step = 3
          }
        });
    },
    async postAccountGetAuthCode() {
      if (this.Countdown > 0) return;
      let data = { account: this.formData.account,type:1 }
      this.formData.authCode = null;
      if (isPhone.test(this.formData.account) || isEmail.test(this.formData.account)) {
        this.Countdown = 60;
        this.setCountdown();
        this.$sensors.track('UM_Click_Verification_Code_Button')
        await this.$api.usercenter.getAuthCodeCommon({ data })
      }else{
        this.$message.error('请输入正确的手机号/邮箱账号')
      }
    },
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    comparePassword(){
      let result = true
      if(this.formData1.password && this.formData1.rePassword){
        if(this.formData1.password !== this.formData1.rePassword){
          this.$message.warning('两次密码输入不一致')
          result = false
        }
      }
      return result
    }
  }
};
</script>

<style lang="stylus" scoped>
@import './../css/login'
  .forget
    .login{
      width 844px
      height 520px
      .login-left{
        width 347px
        height 520px
      }
      .login-box{
        padding 83px 50px 0
        flex 1
        .title{
          font-size 20px
          font-weight 600
          color #316FFF
          line-height 28px
        }
        .progress{
          position relative
          width 310px
          height 2px
          margin-top 40px
          margin-left 46px
          .line1,.line2{
            position absolute
            top 0
            width 141px
            height 2px
            background #E6E6E6
            &.active{
              background #316FFF
            }
          }
          .line1{
            left 0
          }
          .line2{
            right  0
          }
          .outer-1,.outer-2,.outer-3{
            position absolute
            top -14px 
            width 28px
            height 28px
            line-height 28px
            text-align center
            background rgba(191,200,217,.6)
            border-radius 50%
            &.active{
              .inner{
                background #316FFF
              }
              .txt{
                color #393C4C
              }
            }
            .inner{
              display inline-block
              width 20px
              height 20px
              background #BFC8D9
              border-radius 50%
              line-height 20px
              text-align center
              color #ffffff
              font-size 14px
            }
            .txt{
              position absolute
              left 50%
              top 30px
              transform translateX(-50%)
              width 100px
              color #BFC8D9
            }
          }
          .outer-1{
            left -28px
          }
          .outer-2{
            left 50%
            transform translateX(-50%)
          }
          .outer-3{
            right -28px
          }
        }
        .form{
          margin-top 60px
          /deep/ .el-button{
            background #316FFF
            >span{
              color #ffffff
            }
          }
          /deep/ .el-form-item{
            margin-top 30px
          }
          /deep/ .el-input__inner{
            background #fff
            border-top 0px
            border-left 0px
            border-right 0
            border-radius 0
            padding-left 4px
          }
          /deep/ .el-form-item__error{
            margin-left 4px
            left 0
          }
          .success{
            .success-txt{
              font-family PingFangSC-Semibold, PingFang SC
              font-weight 600
              font-size 30px
              color #316FFF
              margin-bottom 10px
            }
            .tip{
              font-family PingFangSC-Regular, PingFang SC
              font-size 16px
              color #666666
            }
          }
        }
        .btn{
          display flex
          align-items center
          margin-top 60px
          .loginbtn{
            width 190px
            height 36px
            margin-right 20px
            padding 0
            border-radius 22px
            background #316fff
          }
          /deep/ .el-button {
            color: #fff;
          }
          .loginbtn.yingji{
            background: #D63535;
          }
          .loginbtn.yingji:hover{
            background: #D63535 !important;
          }
          .loginbtn.wanghong{
            background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%);
          }
          .loginbtn.wanghong:hover{
            background: linear-gradient(147deg, #7D7EFF 0%, #FF48A7 100%) !important;
          }
          /deep/ .el-button--primary span{
            font-size 14px
          }
        }
      }
    }
</style>
